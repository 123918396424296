.blog-container {
  max-width: 700px;
}

div.blog-content {
  // Headings

  h1 {
    @apply text-5xl;
    @apply mt-3 mb-8;
  }

  h2 {
    @apply text-4xl;
    @apply mt-3 mb-6;
  }

  h2.subtitle {
    @apply text-3xl;
    @apply opacity-50;
    @apply font-normal;
  }

  h3 {
    @apply text-2xl;
    @apply mt-3 mb-6;
  }

  h4 {
    @apply text-xl;
    @apply mt-3 mb-2;
  }

  h5 {
    @apply text-lg;
    @apply mt-0 mb-0;
  }

  p {
    @apply text-lg;
    @apply pt-1 pb-8;
    font-weight: 300;
  }

  p a {
    @apply text-blue;
    @apply hover:text-blue-dark;
  }

  ul {
    @apply list-disc;
    @apply ml-8;
    @apply text-lg;
    @apply leading-8;
    @apply pb-8;
  }

  ol {
    @apply ml-0;
    @apply text-lg;
    @apply leading-8;
    @apply pb-8;
  }

  ul ul {
    @apply pb-0;
  }

  ol ol {
    @apply pb-0;
  }

  ol li {
    @apply mb-3;
  }

  li {
    @apply mb-2;
    font-weight: 300;
    @apply text-lg;
  }

  b, strong {
    font-weight: 600;
  }

  figure {
    margin: 2em 0;
  }

  figure > img {
    border-radius: 0.5rem;
    overflow: hidden;
    margin-bottom: 0.5em;
  }

  figure > figcaption,
  figure > figcaption > a {
    color: #696969;
    font-size: 12px;
  }

  blockquote {
    margin-bottom: 50px;
  }

  blockquote > p {
    font-weight: 700;
    color: #6c84fa;
    margin-top: 1em;
    padding-bottom: 20px;
  }

  blockquote > cite {
    font-style: normal;
    font-weight: 700;
    font-size: 0.875em;
    color: #696969;
    margin-top: 1em;
  }
  a {
    font-weight: 700;
    text-decoration: underline;
  }

  .wp-block-separator {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  table {
    @apply min-w-full divide-y divide-gray-300;
  }

  tbody {
    @apply divide-y divide-gray-200 bg-white;
  }

  td {
    @apply px-3 py-4 text-sm;
  }
}
